var Time = (function () {
    return {
        add: add, // Addition
        tzApi: tzApi, // Change timeZone of API to local time
        tzrApi: tzrApi, // Change timeZone of API to Seoul time
        format: format, // 시간 타입만 변경
        isToday: isToday, // 입력된 날짜가 오늘인지 확인
        fromNow: fromNow, // 지금으로 부터 시간 확인 (알림에서 사용)
        subtract: subtract, // Subtraction
        dayjsObj: dayjsObj,
        localTime: localTime, // 현지 시간
        minToHour: minToHour, // Convert minutes to hour with internationalization
        isYesterday: isYesterday, //입력된 날짜가 내일인지 확인
        isThisWeek: isThisWeek, //입력된 날짜가 이번달인지 확인
        isThisMonth: isThisMonth, //입력된 날짜가 이번주인지 확인
        currentTime: currentTime, // 현재 시간 반환
        currentDate: currentDate, // 현재 시간 반환
        roundMinutes: roundMinutes, // 분 단위로 반 올림
        currentTimeZone: currentTimeZone, // 현재 타임존 지역
        commentTzApi : commentTzApi, //댓글 전용 tzApi
        tzrDtm : tzr,
        tzByRegion : tzByRegion,
    }

    // Seoul -> Local Time
    function tz(time, format = reformatByLength(time)) {
        if (!time || time.length < 9 || isNaN(time)) return time;
        const currentTz = currentTimeZone();
        const convert = dayjs.tz(reformatTime(time), getDefaultTimeLocale());
        let converted = convert.tz(currentTz);
        /*if (Often.isElectron() && isElectronDaylightDiff(currentTz)) {
            converted = converted.subtract(1,'hour');
        }*/
        return converted.format(format);
    }

    // Local Time -> Seoul
    function tzr(time, format = reformatByLength(time)) {
        if (!time || time.length < 9 || isNaN(time)) return time;
        return dayjs(reformatTime(time)).tz(getDefaultTimeLocale()).format(format)
    }

    function tzApi(json, target = '_DT', exception = "CONVT") {
        if (getDefaultTimeLocale() === currentTimeZone()) return json;
        return tzJson(json, target, exception);
    }

    function tzrApi(json, target = '_DT', exception = "CONVT") {
        if (getDefaultTimeLocale() === currentTimeZone()) return json;
        return tzJson(json, target, exception, true);
    }

    function add(time = currentTime(), addition, unit = 'day', type = reformatByLength(time)) {
        return dayjs(time).add(addition, unit).format(localizeFormat(type))
    }

    function subtract(time = currentTime(), subtraction, unit = 'day', type = reformatByLength(time)) {
        return dayjs(time).subtract(subtraction, unit).format(localizeFormat(type))
    }

    function minToHour(minute) {
        if (minute < 60) return i18next.t(common.minute, {count: minute});
        else return i18next.t(common.hour, {count: Math.floor(minute / 60)});
    }

    function dayjsObj(time) {
        return dayjs(time);
    }

    function isToday(time = currentTime()) {
        return dayjs(time).isToday();
    }

    function isYesterday(time = currentTime()) {
        return dayjs(time).isYesterday();
    }

    function isThisWeek(time = currentTime()) {
        return dayjs(time) >= dayjs().add('-7', 'day') && dayjs(time) <= dayjs().add('+7', 'day')
    }

    function isThisMonth(time = currentTime()) {
        return dayjs().format(localizeFormat('type10')) === dayjs(time).format(localizeFormat('type10'))
    }

    function tzJson(json, target = '_DT', exception = 'CONVT', isReverse = false) {
        const result = {...json};
        for (let key in json) {
            const value = json[key];
            const isValueDttm = key.includes("VALUE") && value && value.length > 7;
            if ((isValueDttm || key.includes(target) || isValueDttm) && !key.includes(exception)) result[key] = isReverse ? tzr(value) : tz(value);
            if (Array.isArray(value)) result[key] = tzArray(value, target, exception, isReverse)
        }
        return result;
    }

    function tzArray(array, value, exception, isReverse) {
        const result = [];
        array.forEach(json => {
            result.push(tzJson(json, value, exception, isReverse));
        })
        return result;
    }

    function format(time = currentTime(), format = reformatByLength(time)) {
        if (isValid(time)) return '';
        return dayjs(time).format(localizeFormat(format));
    }

    function formatDate(date = currentDate(), format = reformatByLength(date)) {
        if (isValid(date)) return '';
        return dayjs(date).format(localizeFormat(format));
    }

    function currentTime(format = 'YYYYMMDDHHmmss') {
        return dayjs().format(localizeFormat(format));
    }

    function currentDate(format = 'YYYYMMDD') {
        return dayjs().format(localizeFormat(format));
    }

    function roundMinutes(minutesRoundBy) {
        const remainder = minutesRoundBy - (dayjs().minute() % minutesRoundBy);
        return dayjs()
            .add(remainder, "minutes")
            .format("HH mm ss");
    }

    function fromNow(time = currentTime()) {
        if (dayjs().diff(time, 'hours') < 21 && dayjs().diff(time, 'days') === 0)
            return dayjs(time).fromNow()

        return format(time, 'type1');
    }

    function currentTimeZone() {
        return dayjs.tz?.guess() || 'Asia/Seoul';
    }

    function localizeFormat(format) {
        const isRawType = !format.includes('type');
        return isRawType ? format : i18next.t(date.format[format]);
    }

    function reformatTime(time = '') {
        const timeLen = time.length;
        return timeLen === 14 ? time : (time + '00000000000000').substring(0, 14);
    }

    function reformatByLength(time = currentTime()) {
        if (!time) return 'YYYYMMDDHHmmss'
        switch (time.length) {
            case 8:
                return 'YYYYMMDD';
            case 12:
                return 'YYYYMMDDHHmm'
            case 16:
                return 'YYYY-MM-DD'
            default:
                return 'YYYYMMDDHHmmss'
        }
    }

    function isValid(val) {
        return val === null || isNaN(val) || val.trim === '' || val.length === 0
    }

    function localTime(zone = 'Asia/Seoul', format = 'YYYYMMDDHHmmss') {
        return dayjs().tz(zone).format(localizeFormat(format));
    }

    function commentTzApi(str) {
        const dateRegex = /'\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/g;
        const dtmRegex = /\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]),\s(0[1-9]|1[0-9]|2[0-4]):(0[1-9]|[0-5][0-9])/g;

        if(!dateRegex.test(str)) return str;
        const dtmArr = str.match(dtmRegex);
        if(dtmArr != null) {
            dtmArr.forEach(function (unformattedDtm){
                let formattedDtm = formatDate(tz(unformattedDtm.replace(/[-:, \s]/g, '')), 'type25');
                str = str.replace(unformattedDtm, formattedDtm);
            });
        }

        const dateArr = str.match(dateRegex);
        if(dateArr != null) {
            dateArr.forEach(function (unformattedDtm){
                unformattedDtm = unformattedDtm.replaceAll('\'', '');
                let formattedDtm = formatDate(unformattedDtm.replace(/[-:,\s]/g, ''), 'type24');
                str = str.replace(unformattedDtm, formattedDtm);
            });
        }
        return str;
    }

    function getDefaultTimeLocale() {
        return Often.isGlobal()? "Atlantic/Reykjavik" : "Asia/Seoul";
    }

    function tzByRegion(time, region= "KR", format = reformatByLength(time)) {
        const TimeLocale = {
            "KR": "Asia/Seoul",
            "GB": "Europe/London",
            "US": "America/New_York",
            "JP": "Asia/Tokyo",
            "VN": "Asia/Ho_Chi_Minh",
            "KH": "Asia/Phnom_Penh",
            "ETC": "Asia/Seoul"
        }

        const isRegionExist = TimeLocale.hasOwnProperty(region);
        if (!time || time.length < 9 || isNaN(time) || !isRegionExist) return time;
        const convert = dayjs.tz(reformatTime(time), TimeLocale[region]);
        const converted = convert.tz(currentTimeZone())
        return converted.format(format);
    }

    function isSummerTimePeriod() {
        const lastThursDayOfApril = dayjs().month(4).startOf('month').day(4).subtract(1,'week');
        const lastThursDayOfSeptember = dayjs().month(9).startOf('month').day(4).subtract(1,'week');

        return dayjs().isAfter(lastThursDayOfApril) && dayjs().isBefore(lastThursDayOfSeptember);
    }

    //일렉트론 자체오류 잡기위한 로직.. 24버전 이상으로 업데이트 되면 로직 지워주세요!
    function isElectronDaylightDiff(currentTz) {
        //24버전은 윈도우 7,8 버전을 지원하지 않아서 현 플로우 일렉트론 버전 업데이트는 불가.
        //일렉트론 자체 오류고치기 위해서 강제로 한시간 뺴줌.. https://github.com/electron/electron/issues/37972
        //NOTE. 현재 깃허브 이슈로 올라와있는 것만 추가해줬는데 다른 타임존 필요하다면 추가해주세요~
        const timezonesWithDaylightSavingTime = [
            'America/Mexico_City', 'Africa/Cairo'
        ];
        if(timezonesWithDaylightSavingTime.includes(currentTz) && isSummerTimePeriod()) {
            return true;
        }
        return false;
    }

})()
