var Profile = (function () {

    var profileId;
    var dvsnNm = "";

    return {
        openPop: openPop,
        drawProfilePopup: drawProfilePopup,
        convertClphNo: convertClphNo,
    }

    function drawProfilePopup(profileUserId, $triggerTarget) {
        if (profileUserId === "ALL") return;

        profileId = profileUserId;
        var isVisisbleProfilePopup = $(".js-profile-popup").is(":visible");
        var $tempPopup = isVisisbleProfilePopup ? $("#tempPopup") : PopupDraw.getPopupWrapObj();
        // okr 페이지에 깔려있는 #popupWrap html 에는 해당 클래스가 없어 팝업 잘못 출력됨
        if (!$tempPopup.hasClass('flow-all-background-1')) {
            $tempPopup.addClass('flow-all-background-1')
        }
        (!Often.isAct("subscreen") && $tempPopup.css("z-index", 11));
        if (!isVisisbleProfilePopup) $tempPopup.find(".contents").html('')

        $tempPopup.find(".contents").append(` <div class="profile-popup js-profile-popup d-none"></div>`);
        var $profilePopup = $tempPopup.find(".js-profile-popup");

        setProfileData($tempPopup, $profilePopup, $triggerTarget);
        addProfileClickEvent($profilePopup, $triggerTarget);

        if (ElectronApi.Comm.isElectron() && Often.isAct("subscreen")) $profilePopup.append($('#draggableForElectron').html())
    }

    async function setProfileData($tempPopup, $profilePopup, $triggerTarget) {

        if (LocalUtil.getLocal('ONLY_ORG_CHART_ACTIVE_YN') === 'N') {   // 조직도를 한번이라도 사용 후 비활성화시 프로필 부서명 변경
            if (ServerChecker.isKrx) {
                dvsnNm = await AccountSetting.getOriginPrflData(profileId);
                if (dvsnNm.USER_PRFL_IDSRCH_REC.length !== 0) {
                    dvsnNm = dvsnNm.USER_PRFL_IDSRCH_REC[0].DVSN_NM;
                } else {
                    Often.toast("error", "사용 중지된 사용자 입니다.")
                    return;
                }
            } else {
                dvsnNm = await AccountSetting.getOriginPrflData(profileId);
                dvsnNm = dvsnNm.USER_PRFL_IDSRCH_REC[0]?.DVSN_NM;
            }
        }

        return Ajax.executeApi(RestApi.GET.COLABO2_USER_PRFL_R002, {
            SRCH_USER_ID: profileId,
            COLABO_SRNO: window.CONNECT_PROJECT_SRNO
        }, function (dat) {
            $profilePopup.append(getProfileContentHtml(dat));
            if (ServerChecker.isKyowon) { /* 교원인 경우 프로필에 특화 정보 추가 (모바일은 안한다고해서 ACT 새로 만듬) */
                KyowonConfig.getProfileBageTage(profileId, $profilePopup)
            }
            addAdditionalEventForEnter($profilePopup, dat);
            $profilePopup.css("display", "block");
            if (!$(".js-profile-popup").is(":visible")) {
                PopupDraw.closePopup();
                PopupDraw.openTempPopup($tempPopup);
            } else {
                $($(".js-profile-popup")[0]).remove();
            }
            PopupDraw.addEventOnTempPopup($tempPopup);

            var $btnVideo =$profilePopup.find(".btn-wr").find(".js-btn-video");

            const isShowVideoBtn = $btnVideo.length !== 0 && $btnVideo.is(":visible");
            const isHideVideoBtnDisplay = !window.ServerChecker?.isEnter  && isShowVideoBtn;
            if (isHideVideoBtnDisplay) {
                Often.hideOrShowByFunc("ZOOM_MOBILE_ONLY", $btnVideo);
                Often.hideOrShowByFunc("OFF_CHAT_VIDEO_CONFERENCE", $btnVideo);
            }

            // 세코는 따로 처리
            if(window.ServerChecker?.isSeco && Often.isMessenger()) {
                Often.showOrHideByFunc(Func.ENTER.SECO_VIDEO_CONFERENCE , $btnVideo);
            }

            const isSelf = (profileId === _USER_ID);
            // isSelf && $btnVideo.css("display", "none");
            Often.showOrHideByFunc("MESSAGE_POPUP", $(".btn-wr").find(".js-btn-msg"));

            if (Often.isAct("subscreen") && isSelf) {
                $profilePopup.find('.btn-modi').css("display", "none");
                $profilePopup.addClass('mini-profile-popup');
            }

            // okr 에서 정보수정을 시도하면 _PRFL_PHTG 값이 없어 오류 발생
            // okr 에서 쪽지 팝업을 열면 기본적으로 window.close() 하게되어있어 동작이 이상함
            if (Often.isAct('okr')) {
                $profilePopup.find('.btn-wr > .js-btn-modi').hide()
                $profilePopup.find('.btn-wr > .js-btn-msg').hide()
            }

            if(_USE_INTT_ID.includes('SSGC')){
                $('.profile-popup').css('min-height',463);
            }

            const $personalDataEl = $profilePopup.find('#profileEml, #profileClphNo, #profileCmpnTlphNo');

            const PublicProject = requireModule("PublicProject", {on : ["main"]})();
            if (PublicProject?.isInPublicProject($triggerTarget) && profileId !== window._USER_ID){
                $personalDataEl.addClass("public-blur");
            } else {
                $personalDataEl.removeClass("public-blur");
            }

            if (Often.isFunc("WORKER_BOARD") && !Often.isAct("subscreen")) {
                WorkerBoard.addView($tempPopup, dat);

                if (Often.isFunc("WORKER_INSIGHT")) {
                    const $workerBoradBtn = $('.worker-board-btn');
                    $workerBoradBtn.css('font-size', '12px');

                    //worker
                    const $workerInsightBtn = $workerBoradBtn.clone();
                    $workerInsightBtn.css('top', '48px').text('Worker Insight');
                    $workerBoradBtn.after($workerInsightBtn);
                    $workerInsightBtn.off('click').on('click', async () => {
                        PopupDraw.closePopup();
                        await InsightWorker.drawInsightWorker({userId: profileId});
                    });

                    //team
                    const $teamInsightBtn = $workerBoradBtn.clone();
                    $teamInsightBtn.css('top', '76px').text('Team Insight');
                    $workerInsightBtn.after($teamInsightBtn);
                    $teamInsightBtn.off('click').on('click', async () => {
                        PopupDraw.closePopup();
                        await InsightTeam.drawInsightTeam({userId: profileId, dvsnNm});
                    });
                }
                return;
            }

            if (Often.isAct("messenger")) {
                CompanyTip.renderPop({
                    useInttId: dat.USE_INTT_ID,
                    $base: $tempPopup.find('.contents'),
                    buttonCss: {'text-align': 'center'},
                });
            }
            if (!Often.isFunc('IPT_FUNC_KYOWON')) {
                $profilePopup.find('#profileClphNo').removeClass("profile-hover-wrap");
                $profilePopup.find('#profileCmpnTlphNo').removeClass("profile-hover-wrap");
            }
        });
    }

    function addAdditionalEventForEnter($profilePopup, dat) {
        if (_USE_INTT_ID == 'DGBC_1' && dat.USE_INTT_ID == 'DGBC_1') { // DGB캐피탈 내부 전화 솔루션 연결 (DGB캐피탈이고 DGB캐피탈 임직원일 경우)
            $profilePopup.find("#profileCmpnTlphNo span, #profileClphNo span").css('cursor', 'pointer')
                .off("click")
                .on("click", function (e) {
                    const $eTarget = $(e.target);

                    if ($eTarget.closest("#profileCmpnTlphNo").length > 0) {
                        CISCOTEwinOpen(dat.EXTNS_NO);
                    } else if ($eTarget.closest("#profileClphNo").length > 0) {
                        CISCOTEwinOpen(dat.CLPH_NO);
                    } else {
                        return;
                    }
                });

            function CISCOTEwinOpen(lsTLNO) {
                const CISCOTELwin = window.open("CISCOTEL:" + lsTLNO, "CISCOTELWIN", "width=0,height=0,resizable=no,status=no,titlebar=no,toolbar=no,top=0");
                CISCOTELwin.close();
            }
        }
    }

    function getProfileContentHtml(dat) {
        var profileItemHtml = $("#profilePopup").html();
        var isLoginUser = dat.USER_ID === _USER_ID;
        var hasProfileImg = Often.null2Void(dat.PRFL_PHTG).length === 0;

        const _isJoins = ServerChecker.isJoins;
        const _isKyowon = ServerChecker.isKyowon;
        const _isKrx = dat.USE_INTT_ID === 'KRX_1';
        const formatPhoneNumber = (val) => _isKyowon ? val.length > 0 ? val.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3") : '-' : val.length > 0 ? $.trim(val) : '-';
        const userCall = formatPhoneNumber(dat.CMPN_TLPH_NO) + (_isKyowon && Mutil.exists(dat.EXTNS_NO) ? " (" + dat.EXTNS_NO + ")" : "");
        const isShowWorkingTime = Often.isFunc(Func.ENTER.SHOW_WORKING_TIME);
        const isSHowChargeJobNm = Often.isFunc(Func.ENTER.SHOW_CHARGE_JOB_NM);
        const isShowVideoBtn =  !isLoginUser && Often.isFunc("VIDEO_CONFERENCE");
        const setFlexDisplay = (condition) => ListHelper.setDisplay(condition, "flex");

        return ListHelper.replaceJson(profileItemHtml, {
            user_status_display: ListHelper.setDisplay(Often.isFunc("SET_STATUS")),
            btn_modi_display: ListHelper.setDisplay(isLoginUser && !Often.isAct("messenger"), Often.isFunc("PRFL_MODI_BTN_OFF") ? "none" : "flex"),
            btn_video_display: ListHelper.setDisplay(isShowVideoBtn, "flex"),
            company_display: ListHelper.setDisplay(!Often.isFunc(Func.ENTER.HIDE_PRFL_CMNM) && !ServerChecker.isKyowon, "inline-block"),
            list_company_display: ListHelper.setDisplay(!Often.isFunc(Func.ENTER.HIDE_PRFL_COMPANY_INFO) && !ServerChecker.isKyowon,"flex"),
            li_fmc_no_display: ListHelper.setDisplay(Often.isFunc(Func.ENTER.SHOW_FMC_NO), "flex"),
            li_ext_no_display: ListHelper.setDisplay(Often.isFunc(Func.ENTER.SHOW_EXT_NO), "flex"),
            li_eml_display: ListHelper.setDisplay(!Often.isFunc(Func.ENTER.HIDE_PRFL_EML), "flex"),
            li_clph_no_display: ListHelper.setDisplay(!Often.isFunc(Func.ENTER.HIDE_PRFL_CLPH_NO), "flex"),
            li_cmpn_tlph_no_display: ListHelper.setDisplay(!Often.isFunc(Func.ENTER.HIDE_PRFL_CMPN_TLPH_NO), "flex"),
            li_working_time_display: setFlexDisplay(_isKrx || _isJoins || isShowWorkingTime),
            li_dayoff_nm_display: ListHelper.setDisplay(Often.isFunc(Func.ENTER.SHOW_DAY_OFF), "flex"),
            li_emp_no_display: ListHelper.setDisplay(Often.isFunc(Func.ENTER.SHOW_EMP_NO), "flex"),
            li_charge_job_nm_display: setFlexDisplay(_isKrx || isSHowChargeJobNm),
            li_slogan_display: ListHelper.setDisplay(Often.isFunc(Func.CLOUD.SHOW_SLOGAN), "flex"),
            info_badge_display: ListHelper.setDisplay(_isKyowon, "block"),
            slogan_style: _isKyowon ? 'style="overflow-y: scroll; height: 80px;"' : '',
            profile_image: hasProfileImg ? "" : ListHelper.setProfile(dat.PRFL_PHTG),
            IMG_DEFAULT: hasProfileImg ? "default" : "",
            USER_EMAIL: dat.EML.length !== 0 ? dat.EML : "-",
            USER_TXT: dat.SLGN ? dat.SLGN : "-",
            USER_CALL: userCall,
            USER_PHONE: formatPhoneNumber(dat.CLPH_NO),
            USER_NAME: dat.FLNM,
            USER_POSITION: Often.null2Void(dat.JBCL_NM, dat.RSPT_NM),
            USER_COMPANY: ServerChecker.isKyowon? '' : dat.CMNM,
            USER_DEPARTMENT: ServerChecker.isKyowon? Often.isFunc('PART_NM_SHOW') ? [Often.null2Void(dat.DVSN_NM, ''),Often.null2Void(dat.PART_NM, '')].filter((data) => {if(Often.null2Void(data,'') !== '') return true}).join(' | ') : Often.null2Void(dvsnNm, dat.DVSN_NM) : Often.null2Void(dvsnNm, dat.DVSN_NM),
            USER_ID: dat.USER_ID,
            USER_EXTNO: Often.null2Void(dat.EXTNS_NO, "-"),
            USER_FMC_NO: Often.null2Void(dat.FMC_NO, "-"),
            PRFL_CLPH_NO_HOVER: _isKyowon || ServerChecker.isSoil ? "profile-hover-wrap" : "",
            PRFL_CMPN_TLPH_NO_HOVER: _isKyowon || ServerChecker.isSoil ? "profile-hover-wrap" : "",
            USER_FXNO: Mutil.exists(dat.FXNO) ? "Fax :" + dat.FXNO : "",
            USER_WORKING_TIME: Often.null2Void(dat.WORKING_TIME, "-"),
            USER_DAYOFF_NM: Often.null2Void(dat.DAYOFF_NM, "-"),
            USER_EMP_NO: Often.null2Void(dat.EMP_NO, "-"),
            USER_CHARGE_JOB_NM: Often.null2Void(dat.CHARGE_JOB_NM, "-"),
            btn_message_display: ListHelper.setDisplay(Often.isFunc(Func.ENTER.MESSAGE_POPUP), "flex"),
            li_working_stts_display: setFlexDisplay(_isKrx),
            li_working_place_display: setFlexDisplay(_isKrx),
            WORKING_STTS: dat.DAYOFF_NM ? dat.DAYOFF_NM : "-",
            CHARGE_JOB_NM: dat.CHARGE_JOB_NM ? dat.CHARGE_JOB_NM : "-", // 담당업무
            WORKING_PLACE: dat.CMNM ? dat.CMNM : "-", // 근무지(?)
            WORKING_TIME: dat.WORKING_TIME ? dat.WORKING_TIME : "-", // 근무시간
            USER_TIMEZONE: !!dat.TIMEZONE ? Time.localTime(dat.TIMEZONE, "type8") + " local time" : "-",
            display_timezone: ListHelper.setDisplay(Often.isFunc("SHOW_LOCAL_TIME") && !!dat.TIMEZONE, "flex"),
        })
    }

    function isForbiddenChatUser(userId) {
        const forbiddenChatUser = LocalUtil.getLocal("ONLY_FORBIDDEN_CHAT_USER");
        if (!!forbiddenChatUser && forbiddenChatUser.indexOf(userId) > -1) {
            Mutil.toast("info", i18next.t(main.useChatbot));
            return true;
        }
        return false;
    }

    function addProfileClickEvent($profilePopup, $triggerTarget) {
        $profilePopup.off("click").on("click", async function (e) {
            const PublicProject = requireModule("PublicProject", {on : ["main"]})();
            if (PublicProject?.blockEvent('profilePopup.click', e, {profileId, $triggerTarget})) {
                PopupDraw.closePopup();
                return;
            }

            const Tour = Mutil.requireIIFE('Tour', false);

            e.stopPropagation();
            var $eTarget = $(e.target);
            var isMiniPopupProfile = ElectronApi.Comm.isElectron() && Often.isAct("subscreen");
            var isSubScreenProfile = Often.isAct("subscreen");
            var isMonitoring = ('Y' === (LocalUtil.getLocalValue("ONLY_BUY_SETTING", "MONITORING_YN")));

            if ($eTarget.findUp(".js-user-status").length > 0) {
                Often.toast("error", "상태설정 - 준비중!!!");
            } else if ($eTarget.findUp(".js-btn-chat").length > 0) {
                if (Tour?.isBlockNPop()) return PopupDraw.closePopup();

                if (isForbiddenChatUser(profileId)) return;
                if (!isMiniPopupProfile) PopupDraw.closePopup();
                OpenUtil.openMessengerByOneUserId(profileId);
            } else if ($eTarget.findUp(".js-btn-modi").length > 0) {
                if (Tour?.isBlockNPop()) return PopupDraw.closePopup();

                if (!isMiniPopupProfile) PopupDraw.closePopup();
                MySettings.openPopup();
            } else if ($eTarget.findUp(".js-btn-msg").length > 0) {
                if (isMonitoring) {
                    Often.toast("error", "모니터링 계정은 읽기 권한만 가능합니다.");
                    PopupDraw.closePopup();
                    return;
                }
                if (!isMiniPopupProfile) PopupDraw.closePopup();
                await OpenUtil.openLetterDetail('LETTER_SEND', '_profile_message', profileId, "쪽지 보내기");
                if (!(ElectronApi.Comm.isElectronApp() && Often.isAct("subscreen"))) return;
                window.close();
            } else if ($eTarget.findUp(".js-btn-video").length > 0) {
                if (Tour?.isBlockNPop()) return PopupDraw.closePopup();

                if (isForbiddenChatUser(profileId)) return;
                PopupDraw.closePopup();
                if (LimitGuest.isLimitGuest("video", Often.isAct("messenger"))) return;
                const funcVideoConfList = await VideoConference.getFuncVideoConfList();
                if (funcVideoConfList.length > 1 || Often.isFunc(Func.ENTER.SECO_VIDEO_CONFERENCE)) {
                    let videoOrg = Often.isFunc(Func.ENTER.SECO_VIDEO_CONFERENCE) ? "4NB" : $(".js-video-conf-selected-box").find(".video-service-option.on").attr("data");
                    VideoConference.warnAndSendMessage(
                        {
                            type: 'userID',
                            val: $eTarget.closest(".js-btn-video").attr('user_id'),
                            videoOrg: videoOrg,
                        }
                    );
                    return;
                }

                VideoConference.isSynchronizedOnSingleVideo(
                    VideoConference.warnAndSendMessage,
                    VideoConference.alertRequiredZoomSync,
                    {
                        isMini: Often.isAct("messenger"),
                        type: 'userID',
                        val: $eTarget.closest(".js-btn-video").attr('user_id')
                    }
                )
            } else if ($eTarget.findUp('#profileClphNo').length > 0) {
                if (Often.isFunc('IPT_FUNC_KYOWON') || Often.isFunc(Func.ENTER.IPT_FUNC) || Often.isFunc(Func.ENTER.CLICK_TO_CALL)) {
                    if (!ElectronApi.Comm.isElectron()) return;

                    let flnm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.name').text();
                    let cmnm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.company').text();
                    let jbclNm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.position').text();
                    let dvsnNm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.department').text();
                    let number = $eTarget.closest('#profileClphNo').find('span').text();


                    let prflImgTag = $eTarget.parents('.profile-popup').find('.profile-image');
                    let backGroundImg = prflImgTag.css("background-image").replace(/^url\(['"](.+)['"]\)/, '$1').split(',')[0];
                    let defaultPrflCheck = prflImgTag[0].classList.contains('default');

                    let selectPrflImg = "";
                    if (defaultPrflCheck) selectPrflImg = backGroundImg;
                    else selectPrflImg = selectPrflImg = backGroundImg.replace(/\"\)/g, '');

                    let userInfo = {
                        "FLNM": flnm,
                        "CMNM": cmnm,
                        "JBCL_NM": jbclNm,
                        "DVSN_NM": dvsnNm,
                        "NUMBER": number,
                        "SUB_TYPE": "CELL_PHONE",
                        "PRFL_PHTG": selectPrflImg.trim()
                    };

                    if (number) {
                        if (Often.isFunc('IPT_FUNC_KYOWON')) ElectronApi.Ipt.iptGateway({
                            TYPE: "CALL",
                            DATA: userInfo,
                            isCloseWindow: false
                        });
                        else if (Often.isFunc(Func.ENTER.IPT_FUNC)) ElectronApi.Ipt.iptGateway({
                            MODULE_NAME: "SoilConfig.SoilIpt",
                            TYPE: "CALL",
                            DATA: userInfo,
                            isCloseWindow: false
                        });
                        else if (Often.isFunc(Func.ENTER.CLICK_TO_CALL)) EnterCommonConfig.clickToCall(number);
                    }
                }
            } else if ($eTarget.findUp('#profileCmpnTlphNo').length > 0) {
                if (Often.isFunc('IPT_FUNC_KYOWON') || Often.isFunc(Func.ENTER.IPT_FUNC) || Often.isFunc(Func.ENTER.CLICK_TO_CALL)) {
                    if (!ElectronApi.Comm.isElectron()) return;

                    let flnm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.name').text();
                    let cmnm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.company').text();
                    let jbclNm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.position').text();
                    let dvsnNm = $eTarget.parents('.profile-popup').children('.name-card').children('.info').find('.department').text();

                    let prflImgTag = $eTarget.parents('.profile-popup').find('.profile-image');
                    let backGroundImg = prflImgTag.css("background-image").replace(/^url\(['"](.+)['"]\)/, '$1').split(',')[0];
                    let defaultPrflCheck = prflImgTag[0].classList.contains('default');

                    let selectPrflImg = "";
                    if (defaultPrflCheck) selectPrflImg = backGroundImg;
                    else selectPrflImg = selectPrflImg = backGroundImg.replace(/\"\)/g, '');

                    let number = $eTarget.closest('#profileCmpnTlphNo').find('span').text().replace(/\s/gi, '');
                    number = number.replace(/[\{\}\[\]\/?.,;:|\)~`!^\-_+<>@\#$%&\\\=\(\'\"]/g, '');

                    /* 내선번호가 존재할 경우 */
                    if (Often.isFunc('IPT_FUNC_KYOWON')) {
                        if (number.indexOf("*") > -1) number = number.substring(number.indexOf("*"), number.length);
                        else return;
                    }

                    let userInfo = {
                        FLNM: flnm,
                        CMNM: cmnm,
                        JBCL_NM: jbclNm,
                        DVSN_NM: dvsnNm,
                        NUMBER: number,
                        SUB_TYPE: "PHONE",
                        PRFL_PHTG: selectPrflImg.trim()
                    };

                    if (number && number != '') {
                        if (Often.isFunc('IPT_FUNC_KYOWON')) ElectronApi.Ipt.iptGateway({
                            TYPE: "CALL",
                            DATA: userInfo,
                            isCloseWindow: false
                        });
                        else if (Often.isFunc(Func.ENTER.IPT_FUNC)) ElectronApi.Ipt.iptGateway({
                            MODULE_NAME: "SoilConfig.SoilIpt",
                            TYPE: "CALL",
                            DATA: userInfo,
                            isCloseWindow: false
                        });
                        else if (Often.isFunc(Func.ENTER.CLICK_TO_CALL)) EnterCommonConfig.clickToCall(number);
                    }
                }
            } else if ($eTarget.findUp(".btn-close").length > 0) {
                (isMiniPopupProfile || isSubScreenProfile) ? window.close() : PopupDraw.closePopup();
            } else {

            }
        })
    }

    function convertClphNo(str) {
        var convertStr = "";
        str = Often.null2Void(str, "-");
        if (!str.length < 8) {
            convertStr = str.replace(/[^+\-0-9]/g, "");
        } else {
            convertStr = "-"
        }
        return convertStr;
    }

    //모듈을 위한 제반 작업
    function openPop(SRCH_USER_ID) {
        $('body').prepend(`
            <div id="profile" class="popup-dimmed" data-user-id="${SRCH_USER_ID}">
                <div class="contents">
                    <div class="profile-popup js-profile-popup d-none"></div>
                </div>
            </div>
        `);
        renderProfile(SRCH_USER_ID, $("#profile"));
    }

    function closePop() {
        $("#profile").remove();
    }

    function getProfileId() {
        return $("#profile").attr("data-user-id");
    }

    function renderProfile(SRCH_USER_ID, $profile) {
        Ajax.executeApi(RestApi.GET.COLABO2_USER_PRFL_R002, {SRCH_USER_ID}, function (dat) {
            const $profilePopup = $profile.find(".js-profile-popup");
            $profilePopup.append(getProfileContentHtml(dat));
            $profilePopup.find('.btn-modi').css("display", "none");
            $profilePopup.show();
            Often.isFunc("WORKER_BOARD") && WorkerBoard.addView($profile, dat);

            $profile.off("click").on("click", clickProfile);
        });
    }

    function clickProfile(e) {
        if ($.isTarget(e, ".worker-board-btn")) return;

        e.stopPropagation();
        switch (true) {
            case $.isTarget(e, ".popup-dimmed") && !$.isTarget(e, ".profile-popup") : //outClick
            case $.isTarget(e, ".btn-close") :
                closePop();
                break;
            case $.isTarget(e, ".js-btn-chat") :
                if (!isForbiddenChatUser(getProfileId())) {
                    OpenUtil.openMessengerByOneUserId(getProfileId());
                }
                break;

            default :
                break;
        }
    }

})();
