var BrowserNoti = (function () {

    var browserNotiStatus = 0; // 0:초기값, 1:팝업여부1단계, 2:팝업여부2단계, 3:팝업여부 안물음
    var browserNotification = null;
    var currentNotiSrno = null;
    var notificationJson = {};

    return {
        initSetting: initSetting,
        showChattingNotification: showChattingNotification,
        showAlarmNotification: showAlarmNotification,
        requestNotificationPermission: requestNotificationPermission,
        closeAlarmBanner: closeAlarmBanner,
        showLetterNotification: showLetterNotification,
        showCalendarNotification: showCalendarNotification,
    }

    function requestNotificationPermission() {
        Notification.requestPermission(function (result) {
            if (result === 'default') return;
            if (result === 'denied') return closeAlarmBanner();
            if (result === 'granted') {
                closeAlarmBanner();
                Ajax.executeApi(RestApi.PUT.COLABO2_SET_U102, {DESKTOP_ALAM: "Y"});
            } else {

            }
        });
    }

    function closeAlarmBanner(isNeverShow) {
        Top.removeBanner('alarm-step-all');
        browserNotiStatus = 3;
        isNeverShow && LocalUtil.setLocal("ONLY_DESKTOP_NOTI_POPUP", "N");
    }

    /**
     * 배치의 data는 오직 트리거로만 쓰고 있고,
     * 안읽은알림 중 가장 최신의 알림을 가져와 노티를 날려주기때문에
     * 배치 트리거가 오랜만에 왔다면 누락이 될 수도 있다.
     * */
    function showAlarmNotification(data) {
        //Todo. 배치에서 내려오는 data.{COLABO_SRNO, COLABO_COMMT_SRNO, COLABO_REMARK_SRNO}를 활용하기
        // 가스공사 send_yn값으로 모바일이랑 구분하여 주석 처리
        if (!window.ServerChecker?.isKogas && data.SHOW_YN === "N") return; // 배치 SEND_YN 이다. 모바일 푸시와 똑같이 N일시 푸시를 보여주지 않음.
        const userSetting = getUserSetting();
        if (Mutil.empty(userSetting)) {
            //Todo. USER_SETTING 값이 없으면 리로드말고, 다시 API 호출하는 방식으로 변환하기
            location.reload();
            return;
        }
        if (userSetting.FLOW_ALAM_YN === "N" || userSetting.COMMT_ALAM === "N") return; //전체알림 || 프로젝트 알림 off
        if (!isSupportedNotification()) return; //알림 지원하지 않는 브라우저
        if (Notification.permission !== "granted") return;

        Alarm.getRecentAlarmData(async function (dat) {
            var oneAlarmData = Alarm.getOneAlarmData(dat);
            if (Object.keys(oneAlarmData).length === 0) return;
            if (_USER_ID === oneAlarmData.RGSR_ID) return;
            if (checkSendedNoti(oneAlarmData)) return;  //이미 보낸 알람
            const isSendNoti = await PushAlarmSetting.checkProjectPushAlarmSetting(oneAlarmData); // 프로젝트 알림 설정 확인
            if (!isSendNoti) return;
            //Todo. oneAlarmData.RGSN_DTTM 활용 5분 이내의 알람만 허용한다는 방어로직

            showBrowserNoti('ALARM', oneAlarmData, true);
        })
    }

    function showChattingNotification(data) {
        if (isAlarmOn(getUserSetting())) return; //전체알림 || 채팅 알림 off
        if (!isSupportedNotification()) return; //알림 지원하지 않는 브라우저
        if (Notification.permission !== "granted") return;

        var oneMessageData = Chatting.getOneMessageData(data);
        var roomSrno = Often.null2Void(oneMessageData.ROOM_SRNO, data.CHATTING_ROOM_SRNO);
        if ($("body").attr("data-focus-room-srno") === roomSrno) return; //채팅 포커스 상태
        if (Object.keys(oneMessageData).length === 0) return;
        if (_USER_ID === oneMessageData.RGSR_ID) return; //본인

        var roomInfo = LocalUtil.getLocalJson("ONLY_MESSENGER-" + roomSrno);

        if (Mutil.exist(roomInfo)) {
            showBrowserNoti('CHATTING', $.extend(oneMessageData, {ROOM_NM: roomInfo.ROOM_NM}), ("Y" === roomInfo.PUSH_ALAM_YN));
            return;
        }

        Chatting.getRoomInfo({
            "isExecute": false, roomSrno, "callback": function (dat) {
                showBrowserNoti('CHATTING', $.extend(oneMessageData, {ROOM_NM: dat.ROOM_NM}), ("Y" === dat.PUSH_ALAM_YN))
                setTimeout(function () {
                    LocalUtil.removeLocal("ONLY_MESSENGER-" + roomSrno);
                }, 1000 * 60);
            }
        });
    }


    function showLetterNotification(data) {
        if (!Often.isFunc("MESSAGE_POPUP")) return;
        if (data.DELETE_YN === 'Y') return;
        const userSetting = getUserSetting();
        if (!userSetting || userSetting.MESSAGE_ALAM_YN === "N" || userSetting.COMMT_ALAM === "N") return;
        if (!isSupportedNotification()) return; //알림 지원하지 않는 브라우저
        if (Notification.permission !== "granted") return;

        var LetterInfo = LocalUtil.setLocalJson("ONLY_LETTER-" + data.MESSAGE_SRNO + "-" + data.USER_ID + "-" + data.TTL);
        showBrowserNoti('LETTER', data, true);
    }

    function showCalendarNotification(data) {
        let userSetting = LocalUtil.getLocalJson("ONLY_USER_SETTING");
        if (!userSetting || userSetting.MESSAGE_ALAM_YN === "N" || userSetting.COMMT_ALAM === "N") return;
        if (!isSupportedNotification()) return; //알림 지원하지 않는 브라우저
        if (Notification.permission !== "granted") return;
        showBrowserNoti('CALENDAR', data, true);
    }

    /**
     * @description 이미 보낸 알림은 로컬에 넣은 뒤 5분 뒤 삭제한다.
     */
    function checkSendedNoti(oneAlarmData) {
        const isVote = Often.null2Void(oneAlarmData.ALARM_TYPE) === "VT";
        const alarmStatus = Often.null2Void(oneAlarmData.ALARM_STATUS);
        const onlyAlarmList = `${oneAlarmData.COLABO_SRNO}_${oneAlarmData.COLABO_COMMT_SRNO}_${oneAlarmData.COLABO_REMARK_SRNO}${(isVote ? "_" + alarmStatus : "")}`;
        if (LocalUtil.getLocalValue("ONLY_ALARM_LIST", onlyAlarmList)) return true;
        // 프로젝트 푸쉬를 보낼 경우 프로젝트 설정을 통해 푸쉬 여부 확인

        setTimeout(function () {
            LocalUtil.removeLocalValue("ONLY_ALARM_LIST", onlyAlarmList);
        }, 1000 * 300);

        LocalUtil.setLocalValue("ONLY_ALARM_LIST", onlyAlarmList, true);
        return false;
    }

    function showBrowserNoti(code, data, isNotification) {
        var isChatting = code === "CHATTING";
        var isAlarm = code === "ALARM";
        var isLetter = code === "LETTER";
        let isCalendar = code === "CALENDAR";
        var isElectron = ElectronApi.Comm.isElectron();
        var isLockMode = LockControl.isLockMode() || MiniLock.isLockMode();
        var notiSrno = isChatting ? data.ROOM_SRNO ?? data.CHATTING_ROOM_SRNO : 'noti_post';
        let notificationTag = isChatting ? data.ROOM_CHAT_SRNO : isAlarm ? data.ALAM_SRNO : notiSrno;
        let isNewNoti = (browserNotification != null && currentNotiSrno != null && currentNotiSrno !== notificationTag);
        const isMention = isChatting && Often.isFunc("CHAT_MENTION") && data.ELEMENTS_REC?.filter(ELEMENT =>
            ELEMENT.USER_ID === window._USER_ID || ELEMENT.RANGE === 'room').length > 0;
        if (!isLetter && data.READ_STATUS != null && data.READ_STATUS && data.MSG_GB !== 'J') return;
        if (!isNotification && !isMention) return;
        if (isElectron && isChatting && data.MSG_GB === 'J') {
            GroupCall.callReception(data)
            return;
        }

        isNewNoti && initNotification();
        if (isLockMode) {
            notificationJson.ICON = ImageUtil.removeDomain("PROFILE", "");
            notificationJson.BODY = i18next.t(main.alert.unlockToViewMessage);
            if (isChatting) {
                notificationJson.TITLE = i18next.t(main.alert.newAlert, {val: $t(dictionary.message)});
            } else if (isAlarm) {
                notificationJson.TITLE = i18next.t(main.alert.newAlert, {val: $t(dictionary.notification_plural)});
            } else {
                //pass
            }
        } else {
            notificationJson.ICON = ImageUtil.removeDomain("PROFILE", data.PRFL_PHTG);
            if (isChatting) {
                notificationJson.TITLE = TagUtil.html2tag(data.ROOM_NM);
                if (data.CHAT_CODE === SocketAPI.MAIN.DELETE_MESSAGE_ALL_VIEW) {
                    notificationJson.BODY = i18next.t(common.deleted, {val: $t(dictionary.message)});
                } else {
                    data.CNTN = TagUtil.html2tag(data.CNTN); //개선 시 해당 부분 제거
                    const titleTxt = i18next.t(main.alert.userMessage, {
                        user: data.RGSR_NM,
                        interpolation: {escapeValue: false}
                    });
                    notificationJson.BODY = titleTxt + "\n" + Chatting.changeContents(data);
                }
            } else if (isAlarm) {
                data.TASK_NM = TagUtil.html2tag(data.TASK_NM); //개선 시 해당 부분 제거
                const titleTxt = TagUtil.html2tag(data.ALAM_MSG);
                notificationJson.TITLE = TagUtil.html2tag(data.TTL)
                notificationJson.BODY = Often.isEmpty(titleTxt) ? isTaskAlarm(data) :  titleTxt + "\n" + isTaskAlarm(data);
            } else if (isLetter || isCalendar) {
                data.TTL = TagUtil.html2tag(data.TTL); //개선 시 해당 부분 제거
                notificationJson.TITLE = TagUtil.html2tag(data.NOTI_TTL);
                notificationJson.BODY = TagUtil.shortContent(data.NOTI_CNTN, 25)
            } else {
                //pass
            }
        }

        if (isElectron) {
            ElectronApi.Notification.showNoti(notiSrno);
            ElectronApi.Notification.showDenyNoti(notificationJson)

            if (Often.isFunc("DESKTOP_ALARM_WIDGET")) {
                ElectronApi.AlarmWidget.flashAlarmWidget(code);
            }
        }

        currentNotiSrno = notificationTag;
        browserNotification = new Notification(notificationJson.TITLE, {
            icon: notificationJson.ICON,
            body: Mutil.convertHtmlCode(notificationJson.BODY),
            tag: notificationTag ?? 'renotify',
            renotify: true
        });
        browserNotification.onclick = function () {
            event.preventDefault();
            window.focus();
            isElectron && ElectronApi.Window.focusMainBrowser()
            initNotification();
            if (isLockMode) return;

            if (isChatting) {
                OpenUtil.openMessengerByRoomSrno(notiSrno);
                return;
            }

            if (isAlarm) {
                Alarm.closeLayer();
                AlarmUpdate.readAlarmAndAction({
                    COLABO_SRNO: data.COLABO_SRNO,
                    COLABO_COMMT_SRNO: data.COLABO_COMMT_SRNO,
                    COLABO_REMARK_SRNO: data.COLABO_REMARK_SRNO,
                    ALARM_ACTION: data.ALARM_ACTION,
                    OPEN_POP: true,
                    BROWSERNOTI_YN: 'Y',
                    CONTROL_CD: data.CONTROL_CD,
                    CONTROL_MSG: data.CONTROL_MSG,
                })
            }
            if (isLetter) {
                OpenUtil.openLetterDetail('LETTER_DETAIL', '_detail_message', data.MESSAGE_SRNO, i18next.t(common.showDetail) + '-' + data.MESSAGE_SRNO, false, true, false);
            }

            if (isCalendar) {
                ViewChanger.loadPageJson({code: "schd"});
            }
        }
    }

    function initNotification() {
        browserNotification.close();
        currentNotiSrno = null;
    }

    function initSetting() {
        if (!isSupportedNotification()) {
            if (Often.isFunc('IE_BOTTOM_BANNER')) {
                Top.loadDesktopDownBanner();
            }
            return;
        }
        if (Often.isFunc(Func.CLOUD.ALARM_BANNER_VIEW)) return;
        if (Notification.permission === "default") {
            if (Often.isFunc("POPUP_QUEUE") && Often.isFunc("NOTI_REQ_POPUP")) {
                inputPopupQueue("browserPopup", 990, () => {
                    requestNotificationPermission();
                });
                return;
            }

            if (!LocalUtil.getDesktopNotiPopup() === "Y") return;

            // 0:초기값, 1:팝업여부1단계, 2:팝업여부2단계, 3:팝업여부 안물음
            if (browserNotiStatus === 0 || browserNotiStatus === 1) {
                Top.setBanner('alarm-step-1');
                browserNotiStatus = 1;
            } else if (browserNotiStatus === 2) {
                Top.setBanner('alarm-step-2');
                browserNotiStatus = 2;
            } else {
                //pass
            }
        }
    }

    function isSupportedNotification() {
        return (typeof Notification !== "undefined");
    }

    function addFileAndImage(data) {
        var isExistsFile = data.ATCH_YN === "Y";
        var isExistsImage = data.IMG_ATCH_YN === "Y"
        var returnText = "\n(";
        if (isExistsFile && isExistsImage) returnText += i18next.t(dictionary.attachment) + ", " + i18next.t(dictionary.image);
        else if (isExistsFile) returnText += i18next.t(dictionary.attachment);
        else if (isExistsImage) returnText += i18next.t(dictionary.image);
        returnText += ")";
        return (!isExistsFile && !isExistsImage) ? "" : returnText;
    }

    function isTaskAlarm(data) {
        var isTask = ("" !== Often.null2Void(data.TASK_NM, ""));
        if (isTask) {
            return i18next.t(common.taskName) + " : " + TagUtil.shortContent(data.TASK_NM, 25)
              + addTaskContext(data.ALAM_CNTN) + addFileAndImage(data);
        }
        return TagUtil.html2tag(data.ALAM_CNTN) + addFileAndImage(data);
    }

    function addTaskContext(msg) {
        msg = TagUtil.tag2html(msg);
        if (Often.null2Void(msg) === "") return "";
        return "\n" + i18next.t(dictionary.taskContext) + " : " + TagUtil.tag2html(msg);
    }

    function isNotDisturbMode(userSetting) {
        const today = new Date();
        const timeNow = Number(today.getHours().toString() + today.getMinutes().toString().padStart(2, "0"));
        const startTime = Number(userSetting.NOTDISTURB_STIME);
        const endTime = Number(userSetting.NOTDISTURB_ETIME);

        if (userSetting.NOTDISTURB_ALAM === "Y") {
            if (userSetting.NOTDISTURB_DAY.includes(today.getDay())) {
                return true;
            } else if (startTime < endTime && startTime < timeNow && timeNow < endTime) {
                return true;
            } else if (startTime > endTime) {
                if (startTime <= timeNow) {
                    return true;
                } else if (timeNow <= endTime) {
                    return true;
                }
            }
        }
        return false;
    }

    function isAlarmOn(userSetting) {
        return !userSetting || userSetting.CHAT_ALAM_YN === "N" || userSetting.COMMT_ALAM === "N" || isNotDisturbMode(userSetting);
    }

    function getUserSetting() {
        return LocalUtil.getLocalJson("ONLY_USER_SETTING");
    }

})()
