var VideoConference = function () {

    return {
        startVideoConference: startVideoConference,
        updateZoomToken: updateZoomToken,
        deleteZoomToken: deleteZoomToken,
        getZoomUrl: getZoomUrl,
        warnAndSendMessage: warnAndSendMessage,
        OpenMessengerAndSend: OpenMessengerAndSend,
        alertRequiredZoomSync: alertRequiredZoomSync,
        checkOpenWithVCButton: checkOpenWithVCButton,
        createZoomConferenceRoom: createZoomConferenceRoom,
        syncZoom: syncZoom,
        syncTeams: syncTeams,
        syncZoomJWT:syncZoomJWT,
        syncWebExJWT: syncWebExJWT,
        syncWebex: syncWebex,
        unsyncExtService: unsyncExtService,
        isSynchronizedOnSingleVideo: isSynchronizedOnSingleVideo,
        isSynchronizedOnOutService: isSynchronizedOnOutService,
        isSynchronizedOnSelect: isSynchronizedOnSelect,
        isVC: isVC,
        clickZoomComponent: clickZoomComponent,
        getFuncVideoConfList: getFuncVideoConfList,
        convertOrgToNm: convertOrgToNm,
        getParcipateLangCode: getParcipateLangCode,
        getRecentlyVideoOrg: getRecentlyVideoOrg,
        deleteToken: deleteToken,
        updateToken: updateToken,
        syncTeamsJWT: syncTeamsJWT,
        getZoomFreePopup:getZoomFreePopup,
        chat4nbPopupCallback: chat4nbPopupCallback,
    };

    function isVC(vcSrno) {
        return Often.null2Void(vcSrno, "0") !== "0";
    }


    async function getFuncVideoConfList () {
        let videoFuncList = [];
        Often.isFunc("ZOOM_OAUTH") ? videoFuncList.push("Zoom") : "";
        Often.isFunc("ZOOM_JWT") ? videoFuncList.push("ZoomJWT") : "";
        Often.isFunc("MS_TEAMS") ? videoFuncList.push("MsTeams") : "";
        Often.isFunc("MS_TEAMS_JWT") ? videoFuncList.push("MsTeamsJWT") : "";
        Often.isFunc("WEBEX_OAUTH") ? videoFuncList.push("WebEx") : "";
        Often.isFunc("WEBEX_JWT") ? videoFuncList.push("WebExJWT") : "";
        Often.isFunc("KNOX_MEET") ? videoFuncList.push("KnoxMeet") : "";
        Often.isFunc(Func.ENTER.SECO_VIDEO_CONFERENCE) ? videoFuncList.push("4NB") : "";
        if(_USE_INTT_ID.indexOf("KYOWON_") > -1) {
            const TEAMS_LICENSE_YN = (await Ajax.executeApi(RestApi.GET.ACT_EMPL_M365_R001, {})).M365_USE_YN
            if(TEAMS_LICENSE_YN === "Y") return ["MsTeams","Zoom"];
            else return ["Zoom"];
        }
        return videoFuncList;
    }
    /**
     * 토큰 생성 및 토큰 정보 획등
     * @param payload
     * @param callback 콜백
     */
    async function startVideoConference(payload, callback, errorCallback) {
        const isSchedule = payload.TYPE === "SCHEDULE";
        const subpath = LocalUtil.getLocal("SUB_PATH")
        const srno = subpath ? subpath.split('/')[1] : '';
        const funcVideoConfList = await getFuncVideoConfList();
        let videoOrg = payload.VIDEO_ORG ? payload.VIDEO_ORG : funcVideoConfList[0];
        let vcStartDttm = isSchedule ? convertDttmForVideoConf("START") : dayjs().utc().format('YYYY-MM-DDTHH:mm:ssZ');
        let vcEndDttm = isSchedule ? convertDttmForVideoConf("END") : dayjs().add('1', 'hour').utc().format('YYYY-MM-DDTHH:mm:ssZ');

        let videoRestApi = "";
        if(Array.isArray(videoOrg)) videoOrg = videoOrg[0];
        switch( videoOrg ) {
            case "Zoom":
            case "ZoomJWT":
            case "KnoxMeet":
                videoRestApi = RestApi.GET.COLABO_ZOOM_TOKEN_R001;
                break;
            case "MsTeams":
            case "MsTeamsJWT":
                videoRestApi = RestApi.GET.COLABO_MSTEAMS_TOKEN_R001;
                break;
            case "WebEx":
            case "WebExJWT":
                vcStartDttm = dayjs(vcStartDttm).add(60,"second").format("YYYY-MM-DDTHH:mm:ssZ");
                vcEndDttm = dayjs(vcEndDttm).add(60,"second").format("YYYY-MM-DDTHH:mm:ssZ");
                videoRestApi = "COLABO_WEBEX_TOKEN_R001";
                break;
            default: videoRestApi = RestApi.GET.COLABO_ZOOM_TOKEN_R001;
        }
        // 삼성 Knox Meet
        if(Often.isFunc("KNOX_MEET")) {
            vcStartDttm = dayjs(vcStartDttm).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'); //2023-01-16T15:19:40Z'
            vcEndDttm = dayjs(vcEndDttm).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        let JSONData = {
            VC_TTL: payload.VC_TTL,
            VC_START_DTTM: vcStartDttm,
            VC_END_DTTM: vcEndDttm,
            CONF_TYPE: "2",
            CONF_TIMEZONE: Time.currentTimeZone(),
            SRNO: srno,
            TYPE: payload.TYPE,
            REST_API: videoRestApi,
        };
        if(videoOrg.indexOf('JWT') > -1) JSONData.TYPE = "JWT";
        getAccessToken(payload, JSONData, callback, errorCallback)
    }

    function getAccessToken(payload, JSONData, callback, errorCallback) {
        Ajax.executeApi(JSONData.REST_API, JSONData, function(dat){
            if(dat.ERR_CD) {
                if(dat.ERR_CD === 'ER5') { //ER5코드일시에는 재연동 요청
                    alertRequiredZoomSync(Often.isAct("messenger"), {
                        isMini: Often.isAct("messenger"),
                        type: 'chat',
                        videoOrg: payload.VIDEO_ORG
                    });
                    return;
                } else {
                    Often.toast('error', dat.ERR_MSG);
                    $("#postPopup").find(".js-complete-btn").text(i18next.t(dictionary.upload)).attr("data-wait-yn", "N");
                    return;
                }
            }
            callback(dat);
        }, errorCallback);
    }

    function updateToken({VC_SRNO, VC_TTL, VIDEO_ORG}, callback, errorCallback) {
        let vcStartDttm = convertDttmForVideoConf("START");
        let vcEndDttm = convertDttmForVideoConf("END");
        let apiNm = "";

        switch (VIDEO_ORG) {
            case "Zoom":
            case "ZoomJWT":
                apiNm = RestApi.PUT.COLABO_ZOOM_TOKEN_U001;
                break;
            case "MS Teams":
            case "MsTeamsJWT":
            case"MS Teams":
                apiNm = RestApi.PUT.COLABO_MSTEAMS_TOKEN_U001;
                break;
            case "WebExJWT":
            case "Cisco Webex":
                vcStartDttm = dayjs(vcStartDttm).add(60,"second").format("YYYY-MM-DDTHH:mm:ssZ");
                vcEndDttm = dayjs(vcEndDttm).add(60,"second").format("YYYY-MM-DDTHH:mm:ssZ");
                apiNm = RestApi.PUT.COLABO_WEBEX_TOKEN_U001;
                break;
        }

        if(Often.isFunc("KNOX_MEET")) {
            vcStartDttm = dayjs(vcStartDttm).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
            vcEndDttm = dayjs(vcEndDttm).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
            apiNm = RestApi.PUT.COLABO_ZOOM_TOKEN_U001;
        }

        var jsonData = {
            VC_TTL,
            VC_SRNO,
            VC_START_DTTM: vcStartDttm,
            VC_END_DTTM: vcEndDttm,
            CONF_TIMEZONE: Time.currentTimeZone(),
        };
        if(apiNm === "") return Often.toast('error', 'video conference error');
        Ajax.executeApi(apiNm, jsonData, callback, errorCallback);
    }

    function updateZoomToken(payload, callback, errorCallback) {
        var vcStartDttm = convertDttmForVideoConf("START");
        var vcEndDttm = convertDttmForVideoConf("END");
        if(Often.isFunc("KNOX_MEET")) {
            vcStartDttm = dayjs(vcStartDttm).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
            vcEndDttm = dayjs(vcEndDttm).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        }

        var zoomJsonData = {
            VC_TTL: payload.VC_TTL,
            VC_SRNO: payload.VC_SRNO,
            VC_START_DTTM: vcStartDttm,
            VC_END_DTTM: vcEndDttm,
            CONF_TIMEZONE: Time.currentTimeZone(),
        };

        Ajax.executeApi(RestApi.PUT.COLABO_MSTEAMS_TOKEN_U001, zoomJsonData, callback, errorCallback);
    }

    function deleteToken({VC_SRNO, VIDEO_ORG}, callback) {
        const jsonData = { VC_SRNO }
        let apiNm = "";
        switch(VIDEO_ORG) {
            case "Zoom":
            case "ZoomJWT" : apiNm = RestApi.DELETE.COLABO_ZOOM_TOKEN_D001;
                break;
            case "MS Teams":
            case "MsTeamsJWT":
            case "MsTeams" : apiNm = RestApi.DELETE.COLABO_MSTEAMS_TOKEN_D001;
                break;
            case "WebExJWT":
            case "Cisco Webex": apiNm = RestApi.DELETE.COLABO_WEBEX_TOKEN_D001;
                break;
            default: apiNm = RestApi.DELETE.COLABO_ZOOM_TOKEN_D001;
        }

        if(apiNm === "") return Often.toast('error', 'video conference error');
        Ajax.executeApi(apiNm, jsonData, callback);

    }

    function deleteZoomToken({VC_SRNO}, callback) {
        var zoomJsonData = { VC_SRNO }
        Ajax.executeApi(RestApi.DELETE.COLABO_ZOOM_TOKEN_D001, zoomJsonData, callback);
    }

    function getRecentlyVideoOrg(callback) {
        Ajax.executeApi(RestApi.GET.ACT_RECENTLY_USE_VIDEO_R001, {}, callback);
    }

    function convertDttmForVideoConf(type) {
        var $editDateTimeArea = $("#editDateTimeArea");
        var dateTime = type === "START" ? $editDateTimeArea.find("#START_DTTM").val() : $editDateTimeArea.find("#END_DTTM").val();
        var formatDateTime = dateTime.slice(0, 4) + "-" + dateTime.slice(4, 6) + "-" + dateTime.slice(6, 8) + "T"
            + dateTime.slice(8, 10) + ":" + dateTime.slice(10, 12) + ":" + dateTime.slice(12, 14)
        return dayjs(formatDateTime).format('YYYY-MM-DDTHH:mm:ssZ');
    }

    /**
     * Zoom의 교유번호룰 URL 취득 후 해당 URL로 줌 열기
     * @param {string} VC_SRNO Zoom 고유 번호
     * @param {boolean} isOpen 화상회의 방을 바로 열지 여부
     * @param {function} [callback] 콜백
     */
    function getZoomUrl(VC_SRNO, isOpen, callback) {
        if (!VideoConference.isVC(VC_SRNO)) return;

        Ajax.executeApi(RestApi.GET.COLABO2_VIDEO_CONFERENCE_R001, {VC_SRNO: VC_SRNO}, function (dat) {
            var result = JSON.parse(dat.RESULT_MSG);
            if (result._VIDEO_ORG === "" && Often.isFunc('ZOOM_FREE')) {
                getZoomFreePopup(false);
                return;
            }
            if(result._VIDEO_ORG === 'ZoomJWT'){
                Ajax.executeApi(RestApi.GET.COLABO_ZOOM_TOKEN_R001, {VC_TTL: result._RESERVATION_ID +"^^"+result._ZOOM_ID, TYPE: "GET_URL"}, function (response) {
                    // response.
                    var url = result._VC_RGSR_ID === _USER_ID && isOpen ? response.VC_START_URL : response.VC_JOIN_URL;
                    isOpen ? VideoConference.createZoomConferenceRoom(url) : callback(url);
                });
            } else if (result._VIDEO_ORG === '4NB') {
                let url = Often.getLocOrigin() + "/flow_enter_video_conference.act?VC_SRNO=" + VC_SRNO + "&USER_NM=" + _USER_NM;
                isOpen ? VideoConference.createZoomConferenceRoom(url) : callback(url);
            } else {
                var url = result._VC_RGSR_ID === _USER_ID && isOpen ? result._START_URL : result._JOIN_URL;
                isOpen ? VideoConference.createZoomConferenceRoom(url) : callback(url);
            }
        });
    }

    function clickZoomComponent(e) {
        var $detailItem = $(e.target).parents(".detail-item");
        var VC_SRNO = Often.null2Void($detailItem.find("#videoSpan").attr("data-vc-srno"), "0");
        if ($.isTarget(e, "#zoomUrlCopy")) return getZoomUrl(VC_SRNO, false, Often.copyUrl);
        if ($.isTarget(e, "#zoomButton")) return getZoomUrl(VC_SRNO, true);
        if ($.isTarget(e, "#hostKeyCopy")) return getHostKey(e);
    }

    /**
     * 화상회의 메세지를 보낼지 경고한 후 sendMessage
     * 상황에 맞는 Alert -> 채팅창 열림 -> 화상회의 메세지 전송
     * @param type roomSrno | projectSrno | userID | groupCode | chat
     * @param value type에 대한 value
     */
    function getHostKey(e) {
        const $eTarget = $(e.target).findUp("#hostKeyWrap");
        let hostKey = Often.null2Void($eTarget.find(".keynum").text());
        Often.copyUrl(hostKey);

    }
    function warnAndSendMessage({type, val, videoOrg, isMini = true}) {
        // if (LimitGuest.isLimitGuest("video")) return;
        // if (Often.isServerModeByHost("DEV_TEST")) {
        //     OpenMessengerAndSend(type, value);
        //     return;
        // }
        alertUsingVideoConference({type, val, videoOrg, isMini});
    }


    // 게스트의 경우 업그레이드 권유
    function limitGuestPopup() {
        PopupDraw.drawBlock({
            class: 'videoConference',
            contents: BLOCK_TYPE.VIDEO,
            callback: {submit: Upgrade.showUpgradeLayer}
        })
    }

    // 엔터프라이즈 권한 Alert
    function enterpriseNoAuth() {
        PopupDraw.drawBlock({
            class: 'videoConference',
            contents: BLOCK_TYPE.AUTHENTICATION,
        })
    }
    // 화상회의 시작 Alert
    async function alertUsingVideoConference({type, val, videoOrg, isMini = true}) {
        if(videoOrg === 'MsTeams') videoOrg = 'MS Teams';
        const funcVideoConfList = await getFuncVideoConfList();
        if(funcVideoConfList.length > 1) {
            PopupDraw.drawVideoConfSelectPopup({
                contents: {main: i18next.t(common.startVideoConference, {val : convertOrgToNm(videoOrg)})},
                callback: {
                    submit: function () {
                        OpenMessengerAndSend(type, val,videoOrg);
                    }
                }
            }, {type, val, isMini});
            return;
        }
        if(funcVideoConfList[0].indexOf("4NB") > -1 && funcVideoConfList.length > 0) {
            let startDate = Time.localTime();
            let endDate = Time.add(date, 1, 'hours')
            let date = Time.currentDate();
            let startTime = startDate.substring(8,14);
            let endTime = endDate.substring(8,14);
            let data4nb = {
                USER_ID: _USER_ID,
                VC_TTL: roomInfo.ROOM_NM,
                VC_DATE: date,
                VC_START_DTTM: startTime,
                VC_END_DTTM: endTime,
                VC_MEMO: roomInfo.ROOM_NM
            }
            return OpenUtil.open4nbPopup("C", data4nb);
        }
        PopupDraw.drawConfirm({
            contents: {main: i18next.t(common.startVideoConference, {val : convertOrgToNm(videoOrg)})},
            callback: {
                submit: function () {
                    OpenMessengerAndSend(type, val, videoOrg);
                },
                cancel:function() {
                    if(Often.isAct('subscreen') && ElectronApi.Comm.isElectronApp()){
                        window.close()
                    } else {
                        PopupDraw.closePopup()
                    }
                }
            }
        });
    }
    function alertRequiredZoomSync(isMini, dat) {
        if (!PlanFunc.isPlanFuncWithPopup("VIDEO_CONFERENCE", "video")) return;
        // if (LimitGuest.isLimitGuest("video", isMini)) return;
        let videoOrg = dat ? dat.videoOrg : "Zoom";
        let submitCallback = syncZoom;

        switch(videoOrg) {
            case "Zoom":
                submitCallback = syncZoom;
                break;
            case "ZoomJWT":
                submitCallback = syncZoomJWT;
                break;
            case "MsTeams":
                submitCallback = syncTeams;
                break;
            case "MsTeamsJWT":
                submitCallback = syncTeamsJWT;
                break;
            case "WebEx":
                submitCallback = syncWebex;
                break;
            case "WebExJWT":
                submitCallback = syncWebExJWT;
                break;
        }
        PopupDraw.drawBlock({
            mini: isMini,
            class: "video",
            contents: {
                title: BLOCK_TYPE.VIDEO_CONNECT.title,
                main: i18next.t(BLOCK_TYPE.VIDEO_CONNECT.main, {val: convertOrgToNm(videoOrg)}),
                submit: BLOCK_TYPE.VIDEO_CONNECT.submit,
                link_text: BLOCK_TYPE.VIDEO_CONNECT.link_text,
            },
            // linkUrl: 'https://support.flow.team/ko/flow/4404603293197',
            callback: {submit: submitCallback, cancel: PopupDraw.closePopup}
        })
    }

    /**
     * 화상회의를 위한 채팅창 열고 보내는 기능
     * 채팅창 열림 -> 화상회의 메세지 전송
     * @param type roomSrno | projectSrno | userID | groupCode | chat
     * @param value type에 대한 value
     */
    function OpenMessengerAndSend(type, value, videoOrg) {
        LocalUtil.setLocal('ONLY_VIDEO_CONFERENCE', 'Y')
        LocalUtil.setLocal('ONLY_VIDEO_ORG', videoOrg);
        switch (type) {
            case 'roomSrno':
                OpenUtil.openMessengerByRoomSrno(value)
                break;
            case 'projectSrno' :
                OpenUtil.openMessengerByProjectSrno(value);
                break;
            case 'userID' :
                OpenUtil.openMessengerByOneUserId(value);
                break;
            case 'groupCode' :
                OpenUtil.openMessengerByFavoriteGroupCode(value);
                break;
            case 'chat' :
                checkOpenWithVCButton();
                break;
        }
    }

    /**
     * 홈페이지에서 화면에서 화상회의를 타고 들어왔을떄를 감지 하기 위함
     * @description Messenger 에서만 사용가능
     */
    async function checkOpenWithVCButton() {
        if ('Y' === LocalUtil.getLocal('ONLY_VIDEO_CONFERENCE')) {
            const videoOrg = LocalUtil.getLocal('ONLY_VIDEO_ORG')
            LocalUtil.removeLocal('ONLY_VIDEO_CONFERENCE');
            LocalUtil.removeLocal('ONLY_VIDEO_ORG');
            const funcVideoConfList = await getFuncVideoConfList();
            if(funcVideoConfList.length > 1 || Often.isFunc(Func.ENTER.SECO_VIDEO_CONFERENCE)) {
                MessengerSend.sendVideoMessage({videoOrg});
            }
            else isSynchronizedOnSingleVideo(MessengerSend.sendVideoMessage, alertRequiredZoomSync, {isMini: true, videoOrg})

        }
    }

    /**
     * 줌 화상 회의를 처음 시작할때
     * @param zoomUrl Zoom URL (연동/ 화상회의)
     */

    function createZoomConferenceRoom(zoomUrl) {
        var popUpInfo = {
            'width': 500,
            'height': 700,
            'left': 100,
            'top': 100
        }
        // if(ServerChecker.isEnter) {
        //     OpenUtil.openUrlOnBrowser(zoomUrl, '_blank', popUpInfo);
        //     return;
        // }
        var isElectron = ElectronApi.Comm.isElectronApp();
        if(isElectron) {
            ElectronApi.Window.open({
                url: zoomUrl,
                title: '_target',
                width: popUpInfo.width,
                height: popUpInfo.height,
                frame: true,
                x: popUpInfo.left,
                y: popUpInfo.top
            })
        } else {
            OpenUtil.openPopup(zoomUrl, '_target', popUpInfo)
        }
    }

    /**
     * 줌 연동이 되어있지 않을시 사용
     */
    function syncZoom(path) {
        var origin = Often.getLocOrigin();
        var zoomUrl = "https://zoom.us/oauth/authorize?response_type=code&client_id=" + getZoomOAuthClientId()
            + "&redirect_uri=" + origin + "/zoom_oauth.act"
            + "&state=" + Often.null2Void(path);
        LocalUtil.setLocal("vc_base_page", "vc_integration");
        window.open(zoomUrl, "_blank", "width=500,height=600");
    }

    function syncZoomJWT(callback) {
        addExtService("ZoomJWT", callback);
    }
    function syncWebExJWT(callback) {
        addExtService("WebExJWT", callback);
    }
    function syncTeamsJWT(callback) {
        addExtService("MsTeamsJWT", callback);
    }
    //소셜로그인이 있는 곳은 도메인체크
    function getZoomOAuthClientId() {
        if(Often.isGlobal()) return "BzFmik0SWqiaPLqC0dt3A";

        switch(true) {
            case _USE_INTT_ID.indexOf("EMRO") > -1 || location.href.indexOf("flow.emro.co.kr") > -1: return "C2mC7hW0Sm6hKv01_yIBiA";
            case _USE_INTT_ID.indexOf("KYOWON") > -1 : return "58AE7zA1Q3CGZmd59ioRyg";
            case _USE_INTT_ID.indexOf("SEM") > -1 : return "4Vfoz3GQby8xk9PbCnyJA";
            case _USE_INTT_ID.indexOf("LOCAL") > -1 || location.href.indexOf("entertest") > -1 : return "MIyRCi7mRaGbnjxJ8hXL2g";
            case _USE_INTT_ID.indexOf("POSCO") > -1 || location.href.indexOf("withworks.posco.net") > -1 : return "IdirDeYLRIGO62FFfYII1Q";
            case _USE_INTT_ID.indexOf("POSCOICT") > -1 || location.href.indexOf("poscodx") > -1 : return "HBELzLYbSrC_Nxzn9X8WA";
            case _USE_INTT_ID.indexOf("SSGC") > -1 || location.href.indexOf("casamia") > -1 : return "Zbf6SrRUQfycYBAoQUqvAg";
            case _USE_INTT_ID.indexOf("ENTER") > -1 : return "jlS8Br_oSKuPzWPzgyvSDg";
            case _USE_INTT_ID.indexOf("HANSOLPNS") > -1 || location.href.indexOf("hantalk-dev.hansol.net") > -1 : return "OqEW4HpFRwq6b4TvgiviQ";
            default: return "k89F6GEhTJyf3s8xlFUEFA";
        }
    }
    function getTeamsOAuthClientId() {
        switch(true) {
            case _USE_INTT_ID.indexOf("KYOWON") > -1  : return {
                client_id: 'ec8b1ab2-a5c9-41c3-983f-c0b00fc8b818',
                tenant_id: '6aba328a-b414-4a52-bf31-46f3e35e8b39',
            }
            case Often.isGlobal() && _USE_INTT_ID.indexOf("BFLOW_300000009787") > -1 : return {
                client_id: '7396615a-f613-4fb0-a2d7-682d74654686',
                tenant_id: '59242678-28eb-4391-831c-23557bc72f5c',
            }
            case _USE_INTT_ID.indexOf("LOCAL") > -1 || location.href.indexOf("entertest") > -1 : return {
                client_id: '9adf41af-3031-41a0-aaad-eeba5a7a35ab',
                tenant_id: 'Q9h8Q~bH.LlvJfq0ysUTgUxY.FZOX3q76D-njaD5',
            }
            case Often.isGlobal() : return {
                client_id: '7da91c41-0c22-4145-b7f3-c064e366f63d',
                tenant_id: 'cd16b666-9fd3-4188-847b-843c857a62d8',
            }
            default: return {
                client_id: 'e7d7139f-f062-4d0e-87a2-04cbd368fd96',
                tenant_id: 'cd16b666-9fd3-4188-847b-843c857a62d8',
            };
        }
    }

    function getWebexOAuthClientId() {
        switch(true) {
            case _USE_INTT_ID.indexOf("LOCAL") > -1 || location.href.indexOf("entertest") > -1 :
                return "C0c227e1ed9fddf077aefea7f37fdb23b3d4a3a323cd6713f66ab6699d083bb27";
            case _USE_INTT_ID.indexOf("JOINS") > -1 :{
                return "C7ad8c7d5e9beb0433df4a815fe346769005fc6e1e78998f75865b68143af4685";
            }
        }
    }

    /**
     * 연동 해제
     * @param callBack 연동 해제후 실행되어야 할 Function
     * @param serviceNm 연동 해제되어야 할 서비스 (ex) Zoom, MsTeams, ZoomJWT, WebEx
     */
    function unsyncExtService(callback, serviceNm) {
        Ajax.executeApi(RestApi.DELETE.FLOW_EXT_SERVICE_D001, {"EXT_SERVICE_NM": serviceNm}, callback);
    }

    /**
     * Zoom 기능이 사용자 계정에 연동 되어있는지 확인
     * 없을 경우 연동 창을 띄어준다.
     * @param zoomExistcallBack 줌 기능이 연동이 되어있을때 실행되어야 할 Function
     * @param zoomNotExistcallBack 줌 기능 기능이 연동이 되어있지 않을때 실행되어야 할  Function
     */

    function isSynchronizedOnOutService(existCallBack, notExistCallBack, dat) {
        Ajax.executeApi(RestApi.GET.FLOW_EXT_SERVICE_R001, {}, function (data) {
            var isDataEmpty = ((data.EXT_SERVICE_REC && data.EXT_SERVICE_REC.length === 0) || data.EXT_SERVICE_REC[0] === "");
            if (!isDataEmpty) {
                $.each(data.EXT_SERVICE_REC, (i, v) => {
                    switch(v.EXT_SERVICE_NM) {
                        case "Zoom":
                            existCallBack($("#zoomConnectBtn"), 'zoom-data');
                            break;
                        case "MsTeams" :
                            existCallBack($("#teamsConnectBtn"), 'teams-data');
                            break;
                        case "ZoomJWT":
                            existCallBack($("#zoomJwtConnectBtn"), 'zoom-jwt-data');
                            break;
                        case "MsTeamsJWT":
                            existCallBack($("#teamsJwtConnectBtn"), 'teams-jwt-data');
                            break;
                        case "WebEx":
                            existCallBack($("#webexConnectBtn"), 'webex-data');
                            break;
                        case "WebExJWT":
                            existCallBack($("#webexJwtConnectBtn"), 'webex-jwt-data');
                            break;
                    }
                })
            }
        })
    }

    // 기능키가 켜진 화상회의가 한개일 때
    async function isSynchronizedOnSingleVideo(existCallBack, notExistCallBack, dat) {
        let param = {}
        if(dat) {
            param = { type: dat.type, val: dat.val, isMini: dat.isMini, videoOrg: dat.videoOrg }
        }
        let videoOrg = await getFuncVideoConfList();
        if (Often.isFunc('ZOOM_FREE') && videoOrg[0] === 'Zoom') {
            if (Often.isAct("messenger")) {
                getZoomFreePopup();
                return;
            } else {
                videoOrg[0] = "ZoomJWT";
            }

        }
        if (videoOrg[0].indexOf("JWT") > -1) {
            existCallBack({
                type: param.type,
                val: param.val,
                videoOrg,
                isMini: param.isMini})
            return;
        }else if(videoOrg[0].indexOf("KnoxMeet") > -1 || videoOrg[0].indexOf("4NB") > -1) {
            existCallBack({type: param.type, val: param.val, videoOrg: videoOrg[0]});
            return;
        }

        Ajax.executeApi(RestApi.GET.FLOW_EXT_SERVICE_R001, {}, function (data) {
            let isExist = false;
            $.each(data.EXT_SERVICE_REC, (i,v) => {
                if(v.EXT_SERVICE_NM === videoOrg[0]) {
                    isExist = true;
                    existCallBack({type: param.type, val: param.val, videoOrg: videoOrg[0]});
                    return false;
                }
            })

            if(!isExist) notExistCallBack(param.isMini, {videoOrg: videoOrg[0]})
        });
    }

    // 기능키가 켜진 화상회의가 여러 개 일 때
    function isSynchronizedOnSelect(existCallBack, notExistCallBack, dat) {
        Ajax.executeApi(RestApi.GET.FLOW_EXT_SERVICE_R001, {}, function (data) {
            let isExist = false;
            $.each(data.EXT_SERVICE_REC, (i, v) => {
                if(dat.videoOrg === v.EXT_SERVICE_NM) {
                    existCallBack();
                    isExist = true;
                }
            });
            if(!isExist) notExistCallBack();
        })
    }

    /**
     * Teams 연동이 안되어 있을 시
     */
    async function syncTeams() {
        const dat = await Ajax.executeApi(RestApi.GET.FLOW_USE_INTT_INFM_R001, {USE_INTT_ID: window.USE_INTT_ID});
        const subDom = Often.null2Void(dat["SUB_DOM"], "").toLowerCase();
        const hostName = location.host;
        let origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        //서브도메인일때
        if(!ServerChecker.isEnter && hostName.startsWith(`${subDom}.`)) {
            origin = origin.replace(`${subDom}.`, '');
        }
        if(origin.indexOf('hotfix') > -1 || origin.indexOf('develop') > -1 ) {
            origin = "https://flow.team";
        }
        const { client_id, tenant_id } = getTeamsOAuthClientId();
        var targetUrl = "https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?" +
            "client_id="+client_id+"&" +
            "response_type=code&" +
            "redirect_uri="+ (origin +"/msteams_oauth.act&")+
            "response_mode=query&" +
            "scope=offline_access%20user.read%20https://graph.microsoft.com/OnlineMeetings.ReadWrite&"+
            "prompt=login&"+
            "state=" + _USER_ID;
        window.open(targetUrl, "_blank", "width=500,height=600");
    }

    function syncWebex(isjwt) {
        const origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        const client_id = getWebexOAuthClientId();
        const targetUrl = `https://webexapis.com/v1/authorize?`+
            `client_id=${client_id}`+
            `&response_type=code&`+
            `redirect_uri=${origin}/cisco_webex_oauth.act${isjwt ? isjwt : ''}&`+
            `scope=meeting%3Arecordings_read%20meeting%3Aadmin_preferences_write%20meeting%3Aadmin_schedule_write%20meeting%3Aadmin_preferences_read%20meeting%3Aschedules_read%20meeting%3Aparticipants_read%20meeting%3Aadmin_participants_read%20meeting%3Apreferences_write%20meeting%3Arecordings_write%20meeting%3Apreferences_read%20meeting%3Aadmin_recordings_read%20meeting%3Aschedules_write%20spark%3Akms%20meeting%3Acontrols_write%20meeting%3Aadmin_recordings_write%20meeting%3Acontrols_read%20meeting%3Aparticipants_write%20spark-admin%3Atelephony_config_read%20spark-admin%3Atelephony_config_write%20meeting%3Aadmin_schedule_read%20meeting%3Aadmin_transcripts_read`+
            `&state=set_state_here`
        window.open(targetUrl, "_blank", "width=500,height=600");
    }

    function addExtService(serviceNm, callback) {
        Ajax.executeApi(RestApi.POST.FLOW_EXT_SERVICE_C001 , {"EXT_SERVICE_NM": serviceNm}, () => {
            callback();
        });
    }

    function convertOrgToNm(videoOrg) {
        if(Array.isArray(videoOrg)) videoOrg = videoOrg[0]
        if(!videoOrg) return;
        switch (videoOrg.replace(/ /gi, '').toLowerCase()) {
            case "zoom":
            case "zoomjwt": return "Zoom";
                break;
            case "msteams":
            case "msteamsjwt": return "MS Teams";
                break;
            case "webex":
            case "webexjwt": return "Cisco Webex";
                break;
        }
    }

    function getParcipateLangCode(videoOrg) {
        let code = common.parcipateVideoConference;
        if(_USE_INTT_ID === 'KYOWON_1') code = common.joinVideoConference;
        else if( videoOrg && videoOrg.indexOf('Zoom') > -1 ) code = common.parcipateVideoConferenceEu;
        return code;
    }

    function getZoomFreePopup(type = true){
        const popUpInfo = {
            'width': 500,
            'height': 700,
            'left': 100,
            'top': 100
        }
        PopupDraw.drawConfirm({
            contents: {main:"Zoom 무료버전으로 로그인이 필요합니다.\n 진행하시겠습니까?"},
            callback: {
                submit: function () {
                    openFreeZoomUrl();
                    if(type && ViewChanger.getCurrentPageId().indexOf('detail') > -1) OpenUtil.openMessengerByProjectSrno(Detail.getProjectSrno())

                },
                cancel:function() {
                    if(Often.isAct('subscreen') && ElectronApi.Comm.isElectronApp()){
                        window.close()
                    } else {
                        PopupDraw.closePopup()
                    }
                }
            }
        });
    }
    function openFreeZoomUrl() {
        const isElectron = ElectronApi.Comm.isElectronApp();
        var popUpInfo = {
            'width': 500,
            'height': 700,
            'left': 100,
            'top': 100
        }
        if (isElectron) OpenUtil.openUrlOnBrowser('https://zoom.us/ko/signin#/login','_target',popUpInfo);
        else OpenUtil.openPopup('https://zoom.us/ko/signin#/login','_target',popUpInfo);
    }

    function chat4nbPopupCallback(dat) {
        let vcSrno = Often.null2Void(dat.ID, "0");
        let message = dat.TTL + "\n" + location.origin + dat.LINK;
        let $messengerInput = $("#messengerInput");
        let $messengerTextInput = $messengerInput.find(".js-messenger-text-input");
        let $messengerButton = $messengerInput.find("#sendMessageButton");
        $messengerTextInput.text(message);
        $messengerButton.removeClass("off").addClass("on");
        $messengerTextInput.focus();
    }
}();
