var AjaxError = (function () {

    let returnErrorMsg = i18next.t(main.alert.errorTryAgain);

    return {
        errorHandle: errorHandle,
        errorPopup: errorPopup,
    };

    function errorHandle(res, errorCode, errorMsg, errorCallback, apiKey, inputJson) {
        if(!!res.COMMON_HEAD.DATA) {
            const data = res.COMMON_HEAD.DATA;
            const code = 'exception:' +  data.ERROR_INTERNATIONAL_CODE;
            const defaultMessage = data.ERROR_MSG
            const alertMessage = i18next.exists(code) ? i18next.t(code) : defaultMessage;
            Often.toast("error", alertMessage)

            if (typeof errorCallback === 'function') {
                errorCallback(res);
            }
            return;
        }

        if (errorCode === 'S0139' || errorCode === 'S0002') { // 세션 관련 에러
            Often.setCookie("LAST_SUB_PATH", "", 30 * 12);
            if(!Often.isAct('main') && !Often.isAct('miniMain')) {
                setTimeout(() => {
                    Often.toast("error", i18next.t(common.noAuth));
                    self.close();
                }, 2000);
                return;
            }
            Mutil.confirm({
                text: {contents: errorMsg || i18next.t(common.noAuth), submit: i18next.t(dictionary.back)},
                display: {cancelBtn: false},
                callback: {
                    submitFn: () => Often.logoutDirect(),
                    cancelFn: () => Often.logoutDirect()
                }
            });
            clearClientDataNSocket();
            return;
        }

        if (errorCode === 'REQ001') { // USER_ID, RGSN_DTTM 필수값입니다! (SecureFilter)
            console.error(errorCode, errorMsg);
            clearClientDataNSocket();
            return;
        }

        if (errorCode === 'WCB010') { // 삭제된 게시물입니다.
            ViewChanger?.loadPage('main');
            Often.toast("error", i18next.t(main.alert.deleted, {val: $t(dictionary.post)}));
            return;
        }

        if (errorCode === "S0029") { // 제목을 입력하세요
            (typeof errorCallback === 'function') && errorCallback(res);
            return Often.toast("error", i18next.t(main.alert.enterWord, {val: $t(dictionary.title)}));
        }

        if (errorCode === "U400") {
            (typeof errorCallback === 'function') && errorCallback(res);
            return Often.toast("error", errorMsg);
        }

        if (errorCode.indexOf("E1") > -1) {
            return Often.toast("error", errorMsg);
        }

        if (errorCode === "S0314") { // 프로젝트 관리자는 최소 한명이 존재해야 합니다.
            Often.toast("error", Often.null2Void(errorMsg, i18next.t(common.alert.projectManagerNeeded)));
            (typeof errorCallback === 'function') && errorCallback(res);
            return;
        }

        if (errorCode === 'SECURE01') { // 현재 접속한 IP는 회사에서 허용하지 않아 로그아웃 합니다.
            const logout = () => { Loading.drawLoadingPop(); Often.logoutDirect();};
            Mutil.confirm({
                text: {contents: i18next.t(common.security.alartSetLoginSecurityDesc),},
                display: {cancelBtn: false},
                callback: {submitFn: logout, cancelFn: logout,}
            });

            clearClientDataNSocket();
            return;
        }

        if (errorCode !== i18next.t(errorCode)) {
            Often.toast("error", Often.null2Void(errorMsg, i18next.t(errorCode)));
            (typeof errorCallback === 'function') && errorCallback(res);
            return;
        }

        if ("" !== errorMsg) {
            returnErrorMsg += '\nERROR : ' + errorMsg;
            !errorMsg.includes("NO_ALERT") && Often.toast("error", errorMsg);
            (typeof errorCallback === 'function') && errorCallback(res);
        }
    }

    function clearClientDataNSocket() {
        const SentryMonitor = Mutil.requireIIFE('SentryMonitor', false);
        if(SentryMonitor){
            SentryMonitor.captureException(JSON.stringify({
                USER_ID : window._USER_ID,
                RGSN_DTTM :  window._RGSN_DTTM,
                USE_INTT_ID : window._USE_INTT_ID,
                DEVICE_ID : Mutil.n2v(Mutil.getCookie("FLOW_DUID")),
            }));
        }

        window._USER_ID = '';
        window._RGSN_DTTM = '';

        const MainSocket = Mutil.requireIIFE('MainSocket', false);
        const Often = Mutil.requireIIFE('Often', false);
        const LocalUtil = Mutil.requireIIFE('LocalUtil', false);
        const StatusSocket = requireModule('StatusSocket', {on: ['main', 'miniMain', 'messenger']});

        SocketControl.getSocket().disconnect();
        MainSocket?.disconnectSocket();
        StatusSocket()?.disconnectStatusSocket();
        LocalUtil.setLocal("ONLY_DEVICE_NM", "");
        LocalUtil.setLocal("ONLY_DEVICE_ID", "");
        ['flowLogin', 'miniflowLogin', 'googleLoginYn', 'LAST_SUB_PATH', 'FLOW_DUID'].forEach((v) => {
            Often?.setCookie(v, '', 30 * 12);
        });
        Often?.setCookie('autoLoginCheckYN', 'N', 30 * 12); //빈값으로 지정하면 디폴트('Y') 처리되기 때문에 N으로 지정해야함.
    }

    function errorPopup(message) {
        const errorMessage = message.apiKey + "/" +
            JSON.stringify(message.inputJson) + "/" +
            JSON.stringify(message.response);
        if (!Often.isServerModeByHost("ALL_TEST")) {
            console.error(errorMessage);
            return;
        }
        PopupDraw.drawConfirm({
            contents: {
                main: returnErrorMsg
            },
            callback: {
                final: function ($popup) {
                    $popup.find(".cancel-event").remove();
                    const $errorCont = $("<div></div>").addClass("error-cont");
                    $errorCont.text(errorMessage);
                    $popup.find(".popup-cont").after($errorCont);
                }
            }
        });
    }
})();
