var Tooltip = (function () {

    var timer;

    return {
        isDrawToolTipAndAction: isDrawToolTipAndAction,
        isMoveToolTipAndAction: isMoveToolTipAndAction,
        isRemoveToolTipAndAction: isRemoveToolTipAndAction,
        isOverText: isOverText,
        getToolTipText: getToolTipText,
        toggleTooltip: toggleTooltip,
    }

    function $detailToolTip() {
        return $("#detailToolTip");
    }

    /**
     * Note. Tooltip Tag
     *
     * ex)
     * <button class="js-mouseover"
     *         mouseover-text="툴팁을<br>표현합니다"
     * ></button>
     *
     * attr - {String})
     * 1. class: js-mouseover 고정 | js-button-tooltip 선택 (버튼에 넣는 툴팁)
     * 2. mouseover-text : tooltip-text
     * - description : 개행은 <br>로 표기
     * - description : 'mouseover-text' 비어있으면, '태그 안의 text'를 툴팁으로 가져옴
     * - description : '태그 안의 text' 비어있으면, 'mouseover-text'를 툴팁으로 가져옴
     */

    function isDrawToolTipAndAction($eTarget, event) {
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            isDrawToolTip(event);
            clearTimeout(timer);
        }, 300)
    }

    function toggleTooltip(event) {
        var $toolTip = $detailToolTip();
        if(event.type === 'mouseout') {
            $toolTip.remove();
            return;
        }

        if ($toolTip.length > 0) {
            $toolTip.remove();
            return;
        }
        isDrawToolTipAndAction($(event.target), event)
        if (event.type === 'mousemove') {
            DocumentEvent.setMousemoveXY(event.pageX, event.pageY);
        }
    }

    function isDrawToolTip(event) {

        //툴팁 체크
        var $toolTip = $detailToolTip();
        if ($toolTip.length > 0) {
            $toolTip.remove();
        }

        var $eTarget = $(event.target);

        // 툴팁 예외
        if ($eTarget.closest(".flatpickr-calendar").length > 0) {
            return false;
        }

        //객체 체크
        var $mouseoverObj = $eTarget.closest(".js-mouseover");
        if ($mouseoverObj.length === 0) {
            return false;
        }

        //텍스트 체크
        var toolTipText = getToolTipText($mouseoverObj);
        if ("" === toolTipText) {
            return false;
        }
        //if (toolTipText.length > 100) toolTipText = toolTipText.substring(0, 100) + "···";

        //오버 텍스트 체크 - 느림
        if ($mouseoverObj.hasClass("js-over-tooltip") &&
            !isOverText($mouseoverObj, toolTipText)) {
            return false;
        }

        //좌우 체크
        let verticalClass = '';
        if ($mouseoverObj.hasClass('left')) verticalClass = 'left'
        if ($mouseoverObj.hasClass('right')) verticalClass = 'right'

        // 툴팁 개행여부 체크
        const lineBreakOption = $eTarget.attr('data-tooltip-linebreak') === 'true'
        const whiteSpace = lineBreakOption ? { "white-space" : 'pre-wrap' } : {}

        //그리기 - 느림
        $("body").prepend(`<span id="detailToolTip" class="tooltip-square ${verticalClass}">${toolTipText}</span>`);

        $detailToolTip().css({ ...calculatorToolTip($detailToolTip(), $mouseoverObj) , ...whiteSpace});

        //경고 툴팁 체크
        if ($mouseoverObj.hasClass('js-warning-tooltip')) $detailToolTip().css('color', '#FF6B6B');

        return true;
    }

    function isMoveToolTipAndAction($eTarget) {
        var $toolTip = $("#detailToolTip");
        if ($toolTip.length === 0) return false;

        var $mouseoverObj = $eTarget.closest(".js-mouseover");
        $toolTip.css(calculatorToolTip($detailToolTip(), $mouseoverObj));
        return true;
    }

    function isRemoveToolTipAndAction($eTarget) {
        var $toolTip = $("#detailToolTip");
        var $removeObj = $eTarget.closest(".js-mouseover");
        if ((!$removeObj || $removeObj.length === 0) && (!$toolTip || $toolTip.length === 0)) return false;
        $toolTip.remove();
        return true;
    }
    function getTempToolTipTxt($targetObj) {

        // 1. mouseover-text 우선순위 1
        const mouseoverText = $.trim(Often.null2Void($targetObj.attr("mouseover-text")));
        const isExistMouseoverData = Mutil.empty(mouseoverText);
        if (!isExistMouseoverData) {
            return mouseoverText;
        }

        // 2. input이면  input 값 우선 순위 2
        if($targetObj.is("input")) {
            return  $targetObj.val();
        }

        //3. DOM의 텍스트 우선순위 3
        const targetText = $.trim($targetObj.text());
        if (targetText !== "") {
            return  targetText;
        }
        
        //4. 위에 경우가 모두 아니면 빈 문자열
        return ""
    }
    function getToolTipText($targetObj) {
        // 우선적으로 target의 text 가져오기
        let tempToolTipText = getTempToolTipTxt($targetObj);
        var toolTipTextArray = tempToolTipText.split("<br>");
        toolTipTextArray.forEach(function (v, i) {
            toolTipTextArray[i] = TagUtil.removeAllTag(TagUtil.tag2html(v));
        })
        return $.trim(toolTipTextArray.join("<br>"));
    }

    function calculatorToolTip($appendDetailToolTip, $mouseoverObj) {
        const isTopToolTip = $mouseoverObj.hasClass('js-top-tooltip');
        const isTmpPostToolTip = $mouseoverObj.hasClass('js-tmp-tooltip');
        const isShorten = $mouseoverObj.hasClass('description-text');
        const linebreak = $mouseoverObj.text().split(/\r\n|\r|\n/).length
        const isLeftToolTip = $mouseoverObj.hasClass('left');
        const isRightToolTip = $mouseoverObj.hasClass('right');
        $appendDetailToolTip.removeClass('up-tooltip');
        $appendDetailToolTip.removeClass('up-square-tooltip');
        let defaultCss = {
            "position": "absolute",
            "z-index": 100000,
            "word-break": "keep-all",
        }

        const toolTipWidth = $appendDetailToolTip.outerWidth();
        const toolTipHeight = Math.min($appendDetailToolTip.outerHeight(), 41);
        const mouseY = DocumentEvent.getMousemoveY();
        const mouseX = DocumentEvent.getMousemoveX();
        const toolTipPosition = (toolTipWidth / 2);
        const windowWidth = $(window).width() + $(document).scrollLeft();
        let top = mouseY + ((isTopToolTip ? -1 : 1) * toolTipHeight) - (isTmpPostToolTip ? 20 : 10) - (isShorten ? (toolTipHeight - 40) : 0);
        let left = mouseX - toolTipPosition + 4;

        if (isTopToolTip) {
            $appendDetailToolTip.addClass('up-tooltip');
        } else if (isRightToolTip) {
            top = mouseY - 20;
            left = mouseX + 20
        } else if (isLeftToolTip) {
            top = mouseY - 20;
            left = mouseX - toolTipWidth - 20
        } else if ($(window).height() < (top + toolTipHeight)) { // Upper
            $appendDetailToolTip.addClass('up-square-tooltip');
            top = mouseY - toolTipHeight - (isTmpPostToolTip ? 20 : 10);
            left = rightLeftTooltip(windowWidth, left, toolTipWidth)
        } else if (windowWidth < (left + toolTipWidth)) { // Right
            left = windowWidth - toolTipWidth;
        } else if (0 > left) { // Left
            left = 0;
        }
        // Lower

        return {...defaultCss, "transform": "translate(" + left + "px, " + top + "px)"};
    }

    function rightLeftTooltip(windowWidth, left, toolTipWidth) {
        if (windowWidth < (left + toolTipWidth)) return windowWidth - toolTipWidth
        else if (0 > left) return 0
        else return left;
    }

    //Note. '...' 줄임 활성화여부 체크
    function isOverText($mouseOverTarget, toolTipText) {
        var targetNodeName = $mouseOverTarget[0].nodeName;
        if (targetNodeName === 'INPUT') targetNodeName = 'SPAN';
        var $tempObj = $(`<${targetNodeName} class="js-over-text-check">${toolTipText}</${targetNodeName}>`)
            .css(getTargetCss($mouseOverTarget));
        $('body').prepend($tempObj);
        var isOver = ($tempObj.width() > $mouseOverTarget.width());
        $tempObj.remove();
        return isOver;
    }

    function getTargetCss($mouseOverTarget) {
        return {
            "z-index": -1,
            "font-size": $mouseOverTarget.css("font-size"),
            "font-weight": $mouseOverTarget.css("font-weight"),
            "text-align": $mouseOverTarget.css("text-align"),
            "position": "absolute"
        }
    }
})();
