var Loading = (function () {

    return {
        drawLoadingPop: drawLoadingPop,
        closeLoading: closeLoading,

        drawLoadingJson: drawLoadingJson,
        closeLoadingPop: closeLoadingPop,
    };

    function drawLoadingPop(isJoin) {
        var waitingContents = i18next.t(common.wait);
        var joinContents = (isJoin ? i18next.t(main.alert.signupProcessing) + "\n" : "");
        waitingContents = joinContents + waitingContents;
        $(".js-loading-popup").remove();
        $('body').prepend(getLoadingPopup(waitingContents));
        $(".js-loading-popup").find(".js-cancel-btn").css("display", "none");
    }

    function drawLoadingJson(dataJson) {
        var popupHtml = getLoadingPopup(dataJson.CONTENTS)
        $('body').prepend(popupHtml);
        var isExistCloseCallBack = (dataJson.CALLBACK && dataJson.CALLBACK.CANCEL);
        var isExistFinalCallBack = (dataJson.CALLBACK && dataJson.CALLBACK.FINAL);
        $($(".js-loading-popup")[0]).attr("id", "js-loading-popup");
        var $tempPopup = $(".js-loading-popup");
        $tempPopup.find(".js-cancel-btn").css("display", isExistCloseCallBack ? "block" : "none");
        isExistCloseCallBack && $tempPopup.find(".js-cancel-btn").off("click").on("click", dataJson.CALLBACK.CANCEL);
        isExistFinalCallBack && dataJson.CALLBACK.FINAL($tempPopup);
    }

    function closeLoadingPop() {
        var $loadingPopup = $("#js-loading-popup");
        var $targetPopup = $($loadingPopup[$loadingPopup.length - 1]);
        $targetPopup.remove();
    }

    function closeLoading() {
        $(".js-loading-popup").remove();
    }

    function getLoadingPopup(contents) {
        return `<div class="flow-all-background-1 zindex1000 js-loading-popup">
            <div class="flow-project-make-1">
                <div class="flow-project-make-2">
                    <div class="loading-popup flow-project-popup-6">
                        <div class="flow-project-header-1">
                            <a href="#" class="js-cancel-btn loading-cancel flow-close-type-1"></a>
                        </div>
                        <div class="flow-content">
                            <div class="flow-content-text">
                                <p class="popup-cont">${contents}</p>
                            </div>
                            <div class="loading">
                                <i class="circle"></i>
                                <i class="circle"></i>
                                <i class="circle"></i>
                                <i class="circle"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`
    }
})();
